<template>
  <DivisiForm mode="Ubah" module="Divisi"> </DivisiForm>
</template>

<script>
import DivisiForm from './form';

const DivisiUpdate = {
  name: 'DivisiUpdate',
  components: { DivisiForm },
};

export default DivisiUpdate;
</script>
